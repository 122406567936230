.hero-wrapper{
    color: white;
    position: relative;
    padding-bottom: 2rem;
    max-height: 34%;
    background: var(--black);
}
.white-gradient{
    background: rgba(255, 255, 255, 0.3);
    width: 20rem;
    height: 20rem;
    filter: blur(225px);
    border-radius: 50%;
    position: absolute;
    top: 3rem;
}
.hero-container{
    justify-content: space-between;
    align-items: flex-end;
}

/* Right Section */
.hero-right{
    margin-right: 6rem;
    margin-bottom: 5rem;
    display: flex;
    width: 28rem;
    
}
.hero-right .img-container{
    position: relative;
    right: 2rem;
}
.img-container{
    width: 35rem;
    height: 35rem;
    overflow: hidden;
    border-radius: 15rem 15rem 0 0;
    border: 8px solid rgba(255, 255, 255, 0.12) ;
}
.img-container>img{
    width: 100%;
    height: 100%;
}

/* Left Section */

.hero-left{
    gap: 2rem;
    margin-left: 12rem;
    margin-bottom: 8rem;
}
.hero-title{
    position: relative;
    z-index: 1;
}
.hero-title>h1{
    font-weight: 600;
    font-size: 3.8rem;
    line-height: 4rem;
}
.orange-circle{
    height: 4rem;
    width: 4rem;
    background: var(--orange-gradient);
    border-radius: 50%;
    position: absolute;
    right: 8.5rem;
    top: -10%;
    z-index: -1;
}
.search-bar{
    background: white;
    border-radius: 5px;
    border: 3px solid rgba(120, 120, 120, 0.3);
    padding: .5rem 1rem;
    justify-content: space-between;
    width: 100%;
}
.search-bar>input{
    border: none;
    outline: none;
}

.stats{
    width: 100%;
    justify-content: space-between;
}
.stat>:nth-child(1){
    font-size: 2rem;
}
.stat>:nth-child(1)>:last-child{
   color: orange;
}

@media (max-width:810px) {
    .hero-right .img-container{
        position: relative;
        left: 12rem;
        width: 35rem;
    }
    .hero-left{
        margin-top: 4rem;
    }
}
@media (max-width: 640px) {
    .hero-wrapper{
        position: relative;
        right: 7rem;
        margin-bottom: 2rem;
        z-index: 1;
    }
    .hero-container{
        margin-top: 27rem;

    }
    .orange-circle{
        margin-top: 4rem;
        margin-left: 32rem;
    }
    .hero-title>h1{
        margin-top: 3rem;
        font-size: 2rem ;
        /* margin-right: 22rem; */
        line-height: 3rem;
    }
    .hero-desc span{
        font-size: .6rem;
    }
    
    .search-bar{
        width: 97%;
        /* height: 4rem; */
       position: relative;
       right: 2rem;
    }
    .img-container{
        width: 39rem;
        height: 35rem;
        overflow: hidden;
        border-radius: 15rem 15rem 0 0;
        border: 8px solid rgba(255, 255, 255, 0.12) ;
    }
    .stats{
        justify-content: center;
        gap: 1rem;
        font-size: .5rem;
        position: relative;
        right: 1rem;
        /* margin-right: 14rem; */
    }
    .hero-right{
        width: 73%;
        /* height: 24rem; */
        position: relative;
        right: 1rem;
    }
}