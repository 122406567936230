.g-wrapper{
    background: white;
}
.inner-container{
    gap: 1.5rem;
    background: #4161df;
    padding: 2rem;
    border-radius: 10px;
    border: 6px solid #5d77d6;
    text-align: center;
    width: 75rem;
    margin-left: 5rem;
}
.inner-container .primaryText{
    font-weight: 600;
    color: white;
}
.inner-container .secondaryText{
    color: rgba(255,255,255,0.587);

}
.inner-container .button{
    background: #5a73d7;
    border: 2px solid white;
    box-shadow: var(--shadow);
    border-radius: 10px;
    color: white;
}

@media (max-width: 500px) {
    .inner-container{
        width: 20rem;
        margin-left: 1.9rem;
    }
    
}